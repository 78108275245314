import React from "react"
import {Container, Menu, MenuItem, Title, Stripes, Number, Quote, QuoteBtn, InputForm, SubTitle} from "../styles/sideMenuStyle"
import StripeImg from "../images/Stripes@2x.png"

const SideMenu = () => (
    <Container>
        <Number>{process.env.GATSBY_PHONE_NUMBER}</Number>
        <Stripes src={StripeImg}/>
        <Title>SERVICE &amp; REPAIR</Title>
        <Menu>
            <MenuItem>Hydraulic Ram Rebuilds</MenuItem>
            <MenuItem>Hydraulic System Repair</MenuItem>
            <MenuItem>Equipment Diagnosis</MenuItem>
            <MenuItem>Electrical Diagnosis</MenuItem>
            <MenuItem>Pin and Bushing Replacement</MenuItem>
            <MenuItem>Undercarriage Repair</MenuItem>
            <MenuItem>Paint and Body</MenuItem>
            <MenuItem>Maintenance Services </MenuItem>
            <MenuItem>Attachment Installation</MenuItem>
            <MenuItem>Tires and Tracks</MenuItem>
            <MenuItem>Glass Replacement </MenuItem>
            <MenuItem>Heavy Equipment Cleaning</MenuItem>
        </Menu>
        <Number>CONTACT OUR TEAM</Number>
        <Stripes src={StripeImg}/>
        <Quote>
            <SubTitle>REQUEST A QUOTE</SubTitle>
            <InputForm type="text" name="name" placeholder="First/Last Name"/>
            <InputForm type="text" name="company" placeholder="Company Name"/>
            <InputForm type="tel" name="Phone" placeholder="Phone Number"/>
            <InputForm type="text" name="repairs" placeholder="Repairs"/>
            <InputForm type="email" name="email" placeholder="Email"/>
            <QuoteBtn>GET A FREE QUOTE</QuoteBtn>
        </Quote>
    </Container>
  )

  export default SideMenu