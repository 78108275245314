import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Bar, Info, Title, SubTitle, Para, LinkBtn, Item, Wrapper} from "../styles/servicesStyles"
import ServiceMenu from "../components/serviceMenu"

const Services = () => (
  <Layout>
    <SEO 
    title="Contact" 
    description="We specialize in all aspects of Construction Equipment Repair and Maintenance"
    keywords={["Hydraulic Ram Rebuilds", "Hydraulic System Repair and Rebuilds", "Engine System Diagnosis and Repair", "Pin and Bushing Replacement", "Undercarriage Repair and Replacement",
              "Equipment Paint and Body Services", "500-2000 HR Maintenance Services", "Attachment and Auxiliary Hydraulic Installation", "Tire and Track Replacement", "Glass Replacement", "Heavy Equipment Cleaning", process.env.GATSBY_PHONE_NUMBER]}
    />
    <Bar>HEAVY EQUIPMENT REPAIR</Bar>
    <Container>
      <Wrapper>
        <Info>
            <Title>Construction &amp; Heavy Equipment Repair in Deer Park, WA</Title>
            <SubTitle>We specialize in all aspects of Construction Equipment Repair &amp; Maintenance</SubTitle>
            <Para>SR Affordable Equipment has a full staff of highly experienced and professionally trained technicians ready to serve you and all your equipment service needs. We specialize in the following services mentioned below. No matter the make or model you need service on, our highly trained technicians are ready to keep you moving.</Para>

            <SubTitle>Hydraulic Ram Rebuilds</SubTitle>
            <Para>SR Affordable Equipment has a full-service hydraulic cylinder rebuild shop. We can service up to a 12″ diameter hydraulic cylinder at any length. Our expert technicians possess all the tools and knowledge necessary to rebuild your rams for a fraction of the cost of big shops and dealers.</Para>
            <Item>Read More: <LinkBtn>Hydraulic Ram Rebuilds</LinkBtn></Item>

            <SubTitle>Hydraulic System Repair and Rebuilds</SubTitle>
            <Para>SR Affordable Equipment also offers full hydraulic pump and motor rebuild services. Our technicians can diagnose and rebuild or repair any hydraulic pump or motor at a price that fits your budget. Contact us today for a quote today.</Para>
            <Item>Read More: <LinkBtn>Hydraulic System Repair and Rebuilds</LinkBtn></Item>

            <SubTitle>Engine System Diagnosis and Repair</SubTitle>
            <Para>If you are having engine problems with your construction equipment, SR Affordable Equipment is here to serve you. Our technicians have years of experience in diagnosing, repairing, and rebuilding almost every diesel engine manufacture including Cat, Deere, Cummins, Perkins, Isuzu, Detroit, Volvo, Komatsu, Bobcat, Yanmar, and many more. Contact SR Affordable Equipment today for a diagnosis and estimate on your diesel engine today.</Para>
            <Item>Read More: <LinkBtn>Engine System Diagnosis and Repair</LinkBtn></Item>

            <SubTitle>Electrical Diagnosis and Repair</SubTitle>
            <Para>If you are having electrical problems with your machine, turn to SR Affordable Equipment for all your diagnosis and repairs. Our expert staff of technicians know every electrical system inside and out. Let us save you time and money! Give us a call for an electrical system diagnosis today.</Para>
            <Item>Read More: <LinkBtn>Electrical Diagnosis and Repair</LinkBtn></Item>

            <SubTitle>Pin and Bushing Replacement</SubTitle>
            <Para>Is your machine getting sloppy? Let us replace your worn out pins and bushings to avoid more costly repairs. Worn out pins and bushings can also greatly hinder a machines productivity and quality of operation if not replaced when needed. Call us today to get a quote on a pins and bushing replacement today.</Para>
            <Item>Read More: <LinkBtn>Pin and Bushing Replacement</LinkBtn></Item>
            
            <SubTitle>Undercarriage Repair and Replacement</SubTitle>
            <Para>Whether you need sprockets, rollers, track chains or grousers, SR Affordable Equipment can help. We stock many undercarriage parts from quality manufacturers like Berco and ITR. We also offer a full-service undercarriage repair and replacement facility. Our technicians have years of experience doing undercarriages on also any type and size of machine. Give us a call for a quote today!</Para>
            <Item>Read More: <LinkBtn>Undercarriage Repair and Replacement</LinkBtn></Item>

            <SubTitle>Equipment Paint and Body Services</SubTitle>
            <Para>SR Affordable Equipment offers a full service paint and body repair facility. We have the capacity to paint almost any size machine. Whether it be a skid steer or a haul truck we offer prices and a quality of work that is unmatched by our competition. Give us a call for a paint quote today!</Para>
            <Item>Read More: <LinkBtn>Equipment Paint and Body Services</LinkBtn></Item>

            <SubTitle>500-2000 HR Maintenance Services</SubTitle>
            <Para>Preventative maintenance is the most important component to the ownership of a piece of equipment. Maintaining your equipment keeps equipment running smoothly and productivity high as well as avoiding costly repairs. SR Affordable Equipment offers 500-2000HR factory quality maintenance packages for every machine. Our professionally trained technicians are fast and efficient to get your machine serviced and back on the job site where it belongs.  Call us today to order your maintenance package today.</Para>
            <Item>Read More: <LinkBtn>500-2000 HR Maintenance Services</LinkBtn></Item>

            <SubTitle>Attachment and Auxiliary Hydraulic Installation</SubTitle>
            <Para>SR Affordable Equipment can install almost any aux hydraulic system for any machine. Whatever your needs may be SR Affordable Equipment can install a quality system that meets your budget. Call SR Affordable Equipment to build your aux system today!</Para>
            <Item>Read More: <LinkBtn>Attachment and Auxiliary Hydraulic Installation</LinkBtn></Item>

            <SubTitle>Tire and Track Replacement</SubTitle>
            <Para>We offer OEM quality rubber tracks, tires and pads at prices that are the lowest in the industry. We can ship direct to your door or you can bring your machine to us and have our technicians replace them for you. Trust SR Affordable Equipment for all your rubber needs today!</Para>
            <Item>Read More: <LinkBtn>Tire and Track Replacement</LinkBtn></Item>

            <SubTitle>Glass Replacement</SubTitle>
            <Para>We offer quality glass replacement for almost any piece of equipment out there. If we don’t have the pieces in stock we can have them made specifically for your machine. We offer polycarbonate/high impact as well as traditional glass replacement. Call SR Affordable Equipment for your window replacements today!</Para>
            <Item>Read More: <LinkBtn>Glass Replacement</LinkBtn></Item>

            <SubTitle>Heavy Equipment Cleaning</SubTitle>
            <Para>Whether you are exporting or just want to clean your piece of equipment after a long job, SR Affordable Equipment can meet your equipment cleaning and detailing needs. Cleaning is great for preventative maintenance as dirt and dust can wear on hydraulic cylinders, filters, seals and hydraulic lines. Keeping your machine clean will help your key system last longer. We use biodegradable cleaners along with industrial pressure washers to bring your machine back to looking its best. Cleaning your machine also keeps you from a costly ticket from the highway patrol when in transport. Turn to SR Affordable Equipment for all your equipment detailing needs today.</Para>
            <Item>Read More: <LinkBtn>HHeavy Equipment Cleaning</LinkBtn></Item>

        </Info>
        <ServiceMenu/>
      </Wrapper>
    </Container>
  </Layout>
)



export default Services
